<template>
  <v-form v-model="valid" ref="form" v-if="event">
    <v-text-field
        label="Event Name"
        v-model="event.name"
        :rules="[rules.required]"
    ></v-text-field>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Event Date"
            prepend-icon="mdi-calendar"
            @click:prepend="menu=true"
            readonly
            :disabled="event_started"
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            range
            no-title
            scrollable
            :readonly="event_started"
            :min="min_date"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu=false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(dates)"
          >
            OK
          </v-btn>
        </v-date-picker>
    </v-menu>
    <v-menu
        ref="start_menu"
        v-model="start_menu"
        :close-on-content-click="false"
        :return-value.sync="accept_start"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="accept_start_formatted"
            label="Start Accepting Photos on"
            prepend-icon="mdi-calendar"
            @click:prepend="start_menu=true"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="accept_start"
            no-title
            scrollable
            :min="min_start"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="start_menu=false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.start_menu.save(accept_start)"
          >
            OK
          </v-btn>
        </v-date-picker>
    </v-menu>
    <v-menu
        ref="until_menu"
        v-model="until_menu"
        :close-on-content-click="false"
        :return-value.sync="accept_until"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="accept_until_formatted"
            label="Stop Accepting Photos on"
            prepend-icon="mdi-calendar"
            @click:prepend="until_menu=true"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="accept_until"
            no-title
            scrollable
            :min="min_date"
            :max="max_end"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="until_menu=false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.until_menu.save(accept_until)"
          >
            OK
          </v-btn>
        </v-date-picker>
    </v-menu>
    <v-text-field prepend-inner-icon="mdi-pound" label="Twitter Hashtag" v-model="event.twitter_tag"></v-text-field>
    <v-btn type="submit" color="secondary" @click.prevent="submit()">Save</v-btn>
    <v-dialog v-if="can_delete" v-model="delete_confirm" max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="error" v-bind="attrs" v-on="on">Delete</v-btn>
      </template>
      <v-card>
        <v-card-title>Delete this event?</v-card-title>
        <v-card-text>
          Deleting this event will remove any attached images. If you have paid for the event you will be refunded your
          payment. Business customers will not be charged for this event on their invoice date.
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="do_delete()">Delete</v-btn>
          <v-btn @click="delete_confirm=false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import API from "@aws-amplify/api";
import {DateTime, Duration} from "luxon";

export default {
  name: "EventSettings",
  props: ['event_id'],
  data: () => ({
      name: null,
      valid: false,
      menu: false,
      until_menu: false,
      start_menu: false,
      rules: {
          required: value => !!value || 'Required.'
      },
      event: null,
      delete_confirm: false
  }),
  computed: {
    can_delete() {
      return DateTime.now() < DateTime.fromISO(this.event.start_date);
    },
    dateRangeText () {
        let out = this.$pretty_date(this.dates[0]);
        if(this.dates[1]) out += ' - ' + this.$pretty_date(this.dates[1]);
        return out;
    },
    accept_start_formatted(){
        return this.$pretty_date(this.event.accept_start);
    },
    accept_until_formatted(){
        return this.$pretty_date(this.event.accept_until);
    },
    min_date(){
      return DateTime.now().toFormat('yyyy-MM-dd');
    },
    min_start(){
      return DateTime.now().minus({weeks: 1}).toFormat('yyyy-MM-dd');
    },
    max_end(){
      return DateTime.fromISO(this.dates[0]).plus({weeks: 1}).toFormat('yyyy-MM-dd');
    },
    event_started(){
      return DateTime.fromISO(this.dates[0]) <= DateTime.now()
    },
    dates:{
      get(){
        return [this.event.start_date, this.event.end_date]
      },
      set(value){
        this.event.start_date = DateTime.fromISO(value[0]).toISO();
        this.event.end_date = DateTime.fromISO(value[1]).toISO();
      }
    },
    accept_until:{
      get(){
        return this.event.accept_until;
      },
      set(value){
        this.event.accept_until = value;
      }
    },
    accept_start:{
      get(){
        return this.event.accept_start;
      },
      set(value){
        this.event.accept_start = value;
      }
    }
  },
  methods: {
    submit(){
      if(this.$refs.form.validate()){
        API.post('gte', '/event', {
          body: this.event,
          headers: { 'Content-Type': 'application/json' }
        });
        this.$store.commit('setEvent', this.event);
        return true;
      }
    },
    do_delete(){
      API.del('gte', `/event/${this.event.id}`).then(()=>{
          this.$store.commit('setEvent', null);
          this.$store.commit('remove_event', this.event.id);
          this.$router.replace('/events')
      });
    }
  },
  mounted(){
    this.event = {...this.$store.state.event}
  }
}
</script>

<style scoped>

</style>
