<template>
    <v-combobox
            ref="cb"
            v-model="select"
            label="Name"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            hide-details
            clearable
            no-filter
            return-object
            menu-props="closeOnClick, closeOnContentClick"
            >
            <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="!search && !select">
                      Type the name of a person to search for
                    </v-list-item-title>
                    <v-list-item-title v-if="search">
                      No persons matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd>/<kbd>return</kbd> to set name
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:selection="data">
                {{ data.item.name }}
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title><v-avatar>
                    <img :src="data.item.thumbnail_url" v-if="data.item.thumbnail_url">
                    <v-icon large v-if="!data.item.thumbnail_url">mdi-account</v-icon>
                </v-avatar>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
            </template>
    </v-combobox>
</template>

<script>
    import API from '@aws-amplify/api';
    export default {
        name: "PersonSelector",
        props: ['event_id', 'person'],
        data: function(){
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
            }
        },
        watch: {
            search(val) {
                val && val !== this.select && this.querySelections(val)
            },
            select: function (selected) {
                if(!selected) return;
                this.$refs.cb.blur();
                this.$emit('change', selected);
            },
            person: {
              handler: function(val) {
                if (val.id != this.select.id) {
                  this.items.push(val);
                  this.select = val;
                }
              },
              deep: true
            }
        },
        methods: {
            querySelections(v) {
                this.loading = true;
                API.get('gte', '/event/' + this.event_id + '/persons?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response;
                    this.loading = false
                });
            }
        },
        mounted() {
            if(this.person) this.items.push(this.person);
            this.select = this.person;
        }    }
</script>

<style scoped>

</style>
