<template>
  <v-container grid-list-md fluid>
      <v-dialog
          v-model="new_event"
      >
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">New Event</v-btn>
        </template>
        <v-card>
        <v-card-title>Name your Event and select a date or dates</v-card-title>
        <v-card-text>
          <Event ref="event" @save_event="create_event($event)"></Event>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="new_event = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="$refs.event.submit() ? new_event = false : null"
          >
            OK
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <div style="height: 10px;"></div>
      <v-row wrap>
          <v-col v-for="event in events" sm="12" md="3">
              <v-card :to="'/event/' + event.id">
                <v-img v-if="event.card_image" :src="event.card_image.thumbnail_urls[300]"></v-img>
                <v-card-title>{{ event.name }}</v-card-title>
                <v-card-text>{{ $pretty_date(event.start_date) }}
                  <span v-if="event.end_date"> - {{ $pretty_date(event.end_date) }}</span><br/>
                  <h4>Text {{ event.short_id }} to {{ phone }}</h4>
                  <p></p>
                  <h4>Email photos to <a :mailto="`${event.short_id}@${email}`" @click.prevent="mail(event)">{{ event.short_id }}@{{ email }}</a></h4>
                </v-card-text>
                <v-card-actions>
                  <v-btn :to="`/event/${event.id}/info`">Upload Instructions</v-btn>
                </v-card-actions>
              </v-card>
          </v-col>
      </v-row>
      <v-row v-intersect="infiniteScrolling">
          <v-col v-if="spinner">
              <v-progress-circular indeterminate :size="100"></v-progress-circular>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import Event from "../components/Event";
import API from '@aws-amplify/api';

export default {
  name: "events",
  components: {Event},
  data: () => ({
    spinner: false,
    new_event: false,
  }),
  computed: {
    events(){
      return this.$store.getters.get_events;
    },
    phone(){
      return process.env.VUE_APP_PHONE;
    },
    email(){
      return process.env.VUE_APP_EMAIL;
    }
  },
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      this.spinner = true;
      this.$store.dispatch('get_events').then(() => {
        this.spinner = false;
        if(!this.$store.state.events.length) this.new_event = true;
      });
    },
    mail(event){
      window.location.href = `mailto:${event.short_id}@${this.email}`;
    },
    create_event(event){
      this.$router.push(`/event/${event.id}`)
    }
  },
  mounted() {
    this.$store.commit('setEvent', null);
  }
}
</script>

<style scoped>

</style>
