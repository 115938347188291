<template>
  <v-app>
    <v-app-bar
      v-if="!$route.path.startsWith('/event/')"
      app
      color="primary"
      dark
      class="d-print-none"
    >
      <div class="d-flex align-center">
        <router-link :to="$store.state.user ? '/events' : '/'"><img src="/gtf-logo-48.png"></router-link>
        <span v-if="$vuetify.breakpoint.smAndUp"><router-link
            :to="$store.state.user ? '/events' : '/'"
            style="text-decoration: inherit; color: inherit;"
        >
          Gather the Event
        </router-link>
          <span v-if="event">
            <br/>{{ event.name }} <small>{{ $pretty_date(event.start_date) }}
            <span v-if="event.end_date"> - {{ $pretty_date(event.end_date) }}</span></small></span>
          </span>

      </div>
      <span>&nbsp;&nbsp;</span>
      <v-btn plain to="/pricing">Pricing</v-btn>
      <v-btn v-if="$vuetify.breakpoint.smAndUp" plain to="/about">About Us</v-btn>
      <v-btn v-if="$vuetify.breakpoint.smAndUp" plain to="/contact">Contact Us</v-btn>
      <v-spacer></v-spacer>

      <UserMenu></UserMenu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer v-if="$vuetify.breakpoint.xsOnly">
      <v-btn plain to="/about">About Us</v-btn>
      <v-btn plain to="/contact">Contact Us</v-btn>
    </v-footer>
    <v-footer v-if="!$route.query.slideshow" class="d-print-none">
              <span style="padding-left: 10px;" class="hidden-md-and-down">Copyright 2022 Gather the Family LLC</span>
              <span style="padding-left: 10px;" class="hidden-lg-and-up">&copy;2021</span>
              <v-spacer></v-spacer>
                <router-link style="padding-right: 15px" to="/privacy" class="hidden-xs-only">Privacy Policy</router-link>
                <router-link style="padding-right: 15px" to="/terms" class="hidden-xs-only">Terms of Service</router-link>
    </v-footer>
  </v-app>
</template>

<script>

import UserMenu from "./components/UserMenu";
export default {
  name: 'App',
  components: {UserMenu},
  data: () => ({
    //
  }),
  computed: {
    event() {
      return this.$store.state.event;
    }
  }
};
</script>
