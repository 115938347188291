<template>
  <v-card style="max-width: 100%;" :to="`/event/${photo.event_id}/photo/${photo.id}`">
    <div style="position: relative">
        <CanvasImage :src="thumbnail_url" :photo="photo"></CanvasImage>
        <v-menu
            :close-on-content-click="false"
            :key = "face.id"
            :nudge-width="200"
            offset-x
            v-for="(face, key, index) in photo.faces"
            v-model="face.menu"
            v-if = "filtered"
          >
              <template v-slot:activator="{ on }">
                  <div class="d-block" :style="face_style(face, face.menu)" @click.stop.prevent="selected_face=index" v-on="on">
                  </div>
              </template>
              <PersonCard :face="face" v-on:close_person_menu = "face.menu=false" :filtered="filtered" :event_id="photo.event_id"></PersonCard>
      </v-menu>
      </div>
  </v-card>
</template>

<script>
import CanvasImage from "./CanvasImage";
import PersonCard from "./PersonCard";

export default {
  components: {CanvasImage, PersonCard},
  name: "Photo",
  props: ["photo", "filtered"],
  computed:{
      thumbnail_url(){
          return this.photo.thumbnail_urls["300"];
      }
  },
  methods: {
    face_style: function (face, selected) {
      let left = face.bounding_box.left;
      let top = face.bounding_box.top;
      ;
      let width = face.bounding_box.width;
      let height = face.bounding_box.height;
      if (this.photo.object_key.includes("jpg") || this.photo.object_key.includes("jpeg")) {
        switch (this.photo.exif_orientation) {
          case 1:
            break;
          case 2:
            break;
          case 3:
            left = 1 - (face.bounding_box.left + width);
            top = 1 - (face.bounding_box.top + height);
            break;
          case 4:
            break;
          case 5:
            break;
          case 6:
            left = 1 - (face.bounding_box.top + height);
            top = face.bounding_box.left;
            break;
          case 7:
            break;
          case 8:
            break;
        }
      }
      return {
        cursor: "pointer",
        position: "absolute",
        top: top * 100 + '%',
        left: left * 100 + '%',
        width: width * 100 + '%',
        height: height * 100 + '%',
        border: "2px solid " + (selected ? 'green' : 'blue'),
      }
    },
  }
}
</script>

<style scoped>

</style>
