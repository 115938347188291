<template>
 <SectionPricingPlan></SectionPricingPlan>
</template>

<script>
import API from '@aws-amplify/api';
import SectionPricingPlan from '../components/PricingPlan';

export default {
  name: "Pricing",
  components: {
    SectionPricingPlan
  },
  computed:{
    event(){
      return this.$store.state.event;
    }
  },
  methods:{
    buy_now(plan_id){
      if(!this.$store.state.user){
        this.$router.push({path: '/signup', query: {'redirect_to': '/pricing'}});
      }
      let event_id = this.event.id;
      if(plan_id=='single') {
        API.get('gte', `/stripe_session?plan=${plan_id}&event_id=${event_id}`).then(result => {
          console.log(result)
          const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
          stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: result.session_id
          }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          });
        })
      }
      else{
        this.$router.push(`/company?event_id=${this.event.id}`)
      }
    }
  },
  mounted(){
    if(this.$route.query.event_id && ! this.$store.state.event){
      if(!(this.$route.query.event_id in this.$store.state.event_dict)){
        API.get('gte', '/event/' + this.$route.query.event_id).then((response) => {
          this.$store.commit('setEvent', response);
        });
      }
      else {
        this.$store.commit('setEvent', this.$store.state.event_dict[this.$route.query.event_id])
      }
    }
  }
}
</script>

<style scoped>

</style>
