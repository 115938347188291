import { render, staticRenderFns } from "./EventPhotos.vue?vue&type=template&id=9441f826&scoped=true"
import script from "./EventPhotos.vue?vue&type=script&lang=js"
export * from "./EventPhotos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9441f826",
  null
  
)

export default component.exports