<template>
  <v-container
      fluid
      grid-list-md
      style="padding-right: 56px;"
  >
      <v-row wrap>
          <template v-for="(person, index) in people">
              <v-col xs="12" sm="4" md="2" :key="person.id">
                  <PeopleCard :event_id="event_id" :person="person" :id="index" @delete_person="delete_person(index)"></PeopleCard>
              </v-col>
          </template>
      </v-row>
      <v-row v-intersect="infiniteScrolling">
          <v-col v-if="spinner">
              <v-progress-circular indeterminate :size="100"></v-progress-circular>
          </v-col>
      </v-row>
    <v-layout v-if="event && no_pics" justify-center>
      <v-card max-width="500px">
        <v-card-title class="text-center">Welcome to Gather the Event</v-card-title>
        <v-card-text>
          <p>You can easily test our platform before buying.  Here's how to get started:</p>
          <ul>
            <li>Upload photos using the upload button or just drag & drop onto the page.</li>
            <li>Email Photos to <a :mailto="email" @click.prevent="mail(event)">{{ email }}</a></li>
            <li>Text Photos via SMS by sending {{ event.short_id }} to <a :href="`sms:${phone}`">{{ phone }}</a></li>
          </ul>
          <p></p>
          <p>Your guests will be able to submit photos after we've received payment.</p>

          <p>You'll also be able to create a custom Twitter hashtag</p>

          <p>Click <a @click="no_pics=false; $router.push(`/event/${event.id}/settings`)">here</a> for all Gather the Event options.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn :to="`/pricing?event_id=${event_id}`">Buy Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>

  </v-container>
</template>

<script>
import API from "@aws-amplify/api";
import PeopleCard from "../components/PeopleCard";

export default {
  components: {PeopleCard},
  name: "EventPeople",
  props: ['event_id'],
  data: () => ({
    no_pics: false,
    spinner: false,
    people: [],
    more_data: true,
    page: 0
  }),
  computed: {
    event(){
      return this.$store.state.event;
    },
    phone(){
      return process.env.VUE_APP_PHONE;
    },
    email(){
      return process.env.VUE_APP_EMAIL;
    }
  },
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.more_data) {
        this.spinner = true;
        API.get('gte', '/event/' + this.event_id + '/people?page=' + this.page).then((response) => {
          this.page += 1;
          this.spinner = false;
          this.people.push(...response);
          if (!response.length) this.more_data = false;
          if(this.people.length == 0 && !this.event.payments && !this.person_id && !this.event.organization_id){
            this.no_pics = true;
          }

        });
      }
    },
    delete_person(index){
      this.people.splice(index, 1);
    }
  }
}
</script>

<style scoped>

</style>
