<template>
    <v-container>
        <Loading :loading="loading"></Loading>
        <v-layout justify-center>
            <v-flex sm6>
                <v-card>
                    <v-card-title><h1>Update Password</h1></v-card-title>
                    <v-card-text>
                        <v-form v-on:submit.prevent="update()">
                            <v-text-field
                                v-model="password"
                                label="Password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                :rules="password_rules"
                                @click:append="show1 = !show1"
                            ></v-text-field>
                            <v-text-field
                                v-model="verify_password"
                                label="Verify Password"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show2 ? 'text' : 'password'"
                                :rules="password_match"
                                @click:append="show2 = !show2"
                            ></v-text-field>
                            <v-btn type="submit">Update Password</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import Loading from '../components/Loading'
    import API from '@aws-amplify/api';

    export default {
        components: {
            Loading
        },
        data: function(){
          return {
            errors: [],
            loading: false,
            show1: false,
            show2: false,
            password: null,
            verify_password: null,
            password_rules: [
                v => v && v.length >= 8 || 'Password must be at least 8 characters long',
                v => /[0-9]/.test(v) || 'Password must contain at least 1 digit',
                v => /[a-z]/.test(v) || 'Password must contain at least 1 lowercase letter',
                v => /[A-Z]/.test(v) || 'Password must contain at least 1 uppercase letter'
            ],
            emailRules: [
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ],
            password_match: [
                v => v == this.password || 'Passwords must match'
            ]
          }
        },
        name: "UpdatePassword",
        methods: {
          update: function(){
            this.loading = true;
            API.post('gte', '/user/' + this.$store.state.user.user_id, {
                body: {password: this.password},
                headers: {'Content-Type': 'application/json'}
            }).then(response => {
                if(this.$route.query["redirect_to"]){
                    this.$router.push(this.$route.query["redirect_to"])
                }
                else {
                    this.$router.push("/events")
                }
            }).catch(()=>{
                this.loading=false
            })
          }
        }
    }
</script>

<style scoped>

</style>
