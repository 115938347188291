<template>
  <v-card>
      <Loading :loading="loading"></Loading>
      <v-card-title primary-title><div class="headline" style="word-break: normal;">Sign Up to create an Event<br>
        </div>
      </v-card-title>
    <v-card-text>
        <v-form ref="form">
            <ThirdPartyLogin v-if="third_party"></ThirdPartyLogin>
            <v-text-field
                :rules="emailRules"
                v-model="email"
                label="Email"
                :error-messages="errors"
                autocomplete="username"
            ></v-text-field>
            <v-text-field
                v-model="password"
                label="Password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                :rules="password_rules"
                hint="Password must be 8 characters long, and contain an uppercase letter, lowercase letter and a number"
                persistent-hint
                @click:append="show1 = !show1"
                autocomplete="new-password"
                error-count="4"
            >
            </v-text-field>
            <v-text-field
                v-model="verify_password"
                label="Verify Password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                :rules="password_match"
                @click:append="show2 = !show2"
                autocomplete="new-password"
            ></v-text-field>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-btn color="primary" @click.prevent="signup">Sign Up</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import Auth from '@aws-amplify/auth';
import Loading from "./Loading";
import ThirdPartyLogin from '@/components/ThirdPartyLogin'

export default {
    components: {
      ThirdPartyLogin,
      Loading},
    props: ['third_party'],
    data: function () {
        return {
            errors: [],
            loading: false,
            show1: false,
            show2: false,
            email: null,
            phone_number: null,
            password: null,
            verify_password: null,
            password_rules: [
                v => v && v.length >= 8 || 'Password must be at least 8 characters long',
                v => /[0-9]/.test(v) || 'Password must contain at least 1 digit',
                v => /[a-z]/.test(v) || 'Password must contain at least 1 lowercase letter',
                v => /[A-Z]/.test(v) || 'Password must contain at least 1 uppercase letter'
            ],
            emailRules: [
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                  ],
            password_match: [
                v => v == this.password || 'Passwords must match'
            ]
        }
    },
    name: "SignUp",
    methods: {
        signup(){
            if (this.$refs.form.validate()) {
                this.loading = true;
                Auth.signOut();
                axios.post(`${process.env.VUE_APP_API_ENDPOINT}/signup`,
                    {email: this.email, phone_number: this.phone_number, password: this.password}).then(response => {
                    console.log(response)
                    gtag('event', 'sign_up', {'method': 'Native'});
                    this.$store.dispatch("client_side_login", response.data).then(() => {
                      this.$router.push({
                        path: this.$route.query.redirect_to || '/events'
                      });
                    });
                    this.loading = false;
                }).catch(error => {
                    this.errors = [error.response.data.Message];
                    this.loading = false;
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
