// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    debug: true,
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": "us-east-2",
    Auth: {
        identityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: "us-east-2"
    },
    "Storage": {
        "AWSS3": {
            "bucket": process.env.VUE_APP_IMAGE_BUCKET,
            "region": "us-east-2"
        }
    },
    "API": {
        "endpoints": [
            {
                "name": "gte",
                "endpoint": process.env.VUE_APP_API_ENDPOINT,
                "region": "us-east-2"
            }
        ]
    }
};


export default awsmobile;
