<template>
  <v-container v-if="event">
    <v-layout justify-center>
      <v-card max-width="500px">
        <v-card-title class="text-center">
          <h1>Thank you for purchasing Gather the Event</h1>
        </v-card-title>
        <v-card-text class="text-center">
          <h2> Your event {{ event.name }} is all set for</h2>
          <h2> {{ $pretty_date(event.start_date) }}</h2>
          <br>
          <h3>To make changes to your event go to <router-link :to="`/event/${event_id}/setttings`">settings</router-link></h3>
          <h3>Click here for <router-link :to="`/event/${event_id}/info`">upload instuctions</router-link> for your guests</h3>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import API from "@aws-amplify/api";

export default {
  name: "PaySuccess",
  props: ['event_id'],
  mounted() {
    if(!(this.event_id in this.$store.state.event_dict)){
      API.get('gte', '/event/' + this.event_id).then((response) => {
        if(!response.payments) response.payments = true;
        this.$store.commit('setEvent', response);
      });
    }
    else {
      this.$store.commit('setEvent', this.$store.state.event_dict[this.event_id])
    }

    gtag("purchase", {currency: "USD", value: "39.99", transaction_id: this.event_id,
      items: [{item_name: "Event"}]});
  },
  computed: {
    event(){
      return this.$store.state.event;
    }
  }

}
</script>

<style scoped>

</style>
