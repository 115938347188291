<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap justify-space-between>
       <v-flex v-for="image in images" shrink>
        <Photo :filtered="person_id ? true : false" :photo="image"></Photo>
       </v-flex>
    </v-layout>
    <v-row v-intersect="infiniteScrolling">
        <v-col v-if="spinner">
            <v-progress-circular indeterminate :size="100"></v-progress-circular>
        </v-col>
    </v-row>
    <v-layout v-if="event && no_pics" justify-center>
      <v-card max-width="500px">
        <v-card-title class="text-center">Welcome to Gather the Event</v-card-title>
        <v-card-text>
          <p>You can easily test our platform before buying.  Here's how to get started:</p>
          <ul>
            <li>Upload photos using the upload button or just drag & drop onto the page.</li>
            <li>Email Photos to <a :mailto="email" @click.prevent="mail(event)">{{ email }}</a></li>
            <li>Text Photos via SMS by sending {{ event.short_id }} to <a :href="`sms:${phone}`">{{ phone }}</a></li>
          </ul>
          <p></p>
          <p>Your guests will be able to submit photos after we've received payment.</p>

          <p>You'll also be able to create a custom Twitter hashtag</p>

          <p>Click <a @click="no_pics=false; $router.push(`/event/${event.id}/settings`)">here</a> for all Gather the Event options.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn :to="`/pricing?event_id=${event_id}`">Buy Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import API from "@aws-amplify/api";
import Photo from "../components/Photo";

export default {
  name: "EventPhotos",
  components: {Photo},
  props: ["event_id", "person_id"],
  data: () => ({
    spinner: false,
    no_pics: false
  }),
  computed: {
    images(){
      return this.$store.state.images;
    },
    event(){
      return this.$store.state.event;
    },
    phone(){
      return process.env.VUE_APP_PHONE;
    },
    email(){
      return `${this.event.short_id}@${process.env.VUE_APP_EMAIL}`;
    }

  },
  watch: {
    person_id: {
      handler: function() {
        if (this.person_id != this.$store.state.image_filter_person) {
          this.$store.commit('clearImages');
          this.spinner = true;
          this.$store.dispatch('get_photos', {person_id: this.person_id}).then(() => {
            this.spinner = false;
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      this.spinner = true;
      this.$store.dispatch('get_photos', {person_id: this.person_id}).then(() => {
        this.spinner = false;
        if(this.images.length == 0 && !this.event.payments && !this.person_id && !this.event.organization_id){
          this.no_pics = true;
        }
      });
    },
    mail(event){
      window.location.href = `mailto:${this.email}`;
    }

  },
}
</script>

<style scoped>

</style>
