import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@aws-amplify/auth';
import API from "@aws-amplify/api";
import facebook from "./facebook"
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
      facebook,
  },
  state: {
      user: null,
      socket: {
        isConnected: false
      },
      processing: {},
      event_dict: {},
      events: [],
      events_more_data: true,
      events_page: 0,
      event: null,
      images: [],
      image_dict: {},
      image_more_data: true,
      image_page: 0,
      image_filter_person: null,
      person: null
  },
  mutations: {
      SOCKET_ONOPEN (state, event)  {
          console.info("WEBSOCKET ONOPEN");
          Vue.prototype.$socket = event.currentTarget
          state.socket.isConnected = true
          const session_id2 = localStorage.getItem('session_id2');
          if(session_id2) {
              Vue.prototype.$socket.sendObj({
                  action: 'login',
                  session_id2: session_id2,
                  processing: Object.keys(state.processing)
              })
          }
          if(state.event) {
              Vue.prototype.$socket.sendObj({
                  action: 'subscribe',
                  event_id: state.event.id
              })
          }
      },
      SOCKET_ONCLOSE (state, event)  {
          state.socket.isConnected = false
      },
      SOCKET_ONERROR (state, event)  {
          console.error(state, event)
      },
      // default handler called for all methods
      SOCKET_ONMESSAGE (state, message)  {
          state.socket.message = message
      },
      // mutations for reconnect methods
      SOCKET_RECONNECT(state, count) {
          console.info(state, count)
      },
      SOCKET_RECONNECT_ERROR(state) {
          console.log('RECONNECT ERROR')
          state.socket.reconnectError = true;
      },
      setUser(state, user) {
          state.user = user;

          if(state.user) {
              gtag('config', 'G-YVZ3WNX0YV', {
                  'user_id': state.user.user_id.toString()
              }); // Set the user ID using signed-in user_id.
              gtag('set', 'user_properties', {
                  google: state.user.google ? true : false,
                  dropbox: state.user.dropbox ? true: false,
                  facebook: state.user.facebook ? true : false
              });
          }
      },
      addEvents(state, events){
          for(let event of events){
              Vue.set(state.event_dict, event.id, event);
              state.events.push(event.id);
          }
      },
      setImageFilterPerson(state, person_id){
          state.image_filter_person = person_id
      },
      setPerson(state, person){
          state.person = person
      },
      clearImages(state){
          state.images = [];
          state.image_more_data = true;
          state.image_page = 0;
          state.image_filter_person = null;
          state.person = null;
      },
      addimages(state, images){
          state.images.push(...images);
          for(let image of images){
              Vue.set(state.image_dict, image.id, image);
          }
      },
      removeImage(state, image){
          Vue.delete(state.image_dict, image.id);
          for(let i=0; i<state.images.length; i++){
              if(state.images[i].id === image.id){
                  state.images.splice(i, 1);
              }
          }
      },
      setEvent(state, event){
          if(!event || !state.event || event.id != state.event.id){
              state.event = event;

              //Clear the event based data
              state.images = [];
              state.image_more_data = true;
              state.image_page = 0;
              state.image_filter_person = null;
              state.person = null;
          }
          if(event) {
              Vue.set(state.event_dict, event.id, event);
              Vue.prototype.$socket.sendObj({
                  action: 'subscribe',
                  event_id: state.event.id
              })
          }
      },
      remove_event(state, event_id){
          Vue.delete(state.event_dict, event_id);
          for(let i=0; i<state.events.length; i++){
              if(state.events[i] === event_id){
                  state.events.splice(i, 1);
              }
          }
      },
      addPhoto(state, photo) {
          state.images.unshift(photo);
      },
      setImage(state, image){
          Vue.set(state.image_dict, image.id, image);
      },

      set_uploading(state, {key, file}){
          file.status = 'uploading';
          Vue.set(state.processing, key, file);
      },
      set_processing(state, {key, file}){
          file.status = 'processing';
          Vue.set(state.processing, key, file);
      },
      set_complete(state, image){
         let processing_id = image.object_key;
         let file = Object.assign(state.processing[processing_id], {status: 'complete', 'image': image});
         Vue.set(state.processing, processing_id, file);
      },
      next_photo_page(state){
          state.image_page +=1;
      },
      no_more_image_data(state){
          state.image_more_data = false;
      },
      next_event_page(state){
          state.events_page +=1;
      },
      no_more_event_data(state){
          state.events_more_data = false;
      }
  },
  getters: {
    get_events: state => {
      let events = [];
      for(let event_id of state.events){
          events.push(state.event_dict[event_id])
      }
      return events;
    }
  },
  actions: {
      client_side_login({commit, state, dispatch}, data){
          return new Promise(function(resolve, reject) {
              commit("setUser", data)
              localStorage.setItem('session_id2', data.session_id2)
              localStorage.setItem('has_account', true);
              if(state.socket.isConnected) {
                  Vue.prototype.$socket.sendObj({
                      action: 'login',
                      session_id2: data.session_id2
                  })
              }
              console.log(data)
              data.aws.expires_at = 15 * 1000 + new Date().getTime();
              Auth.federatedSignIn('cognito-identity.amazonaws.com', data.aws, data).then(cred =>{
                  resolve();
              });
          });

      },
      logout({commit, state, dispatch}, data){
          if(state.user && state.user.google){
              var auth2 = Vue.prototype.$gapi.logout();
          }
          localStorage.removeItem("session_id2");
          commit('setUser', null);
          Auth.signOut();
          window.location.assign('/');
      },
      external_systems_check({commit, state, dispatch}, data){
          return new Promise(function(resolve, reject) {
              if (!state.user) {
                  let request = {system_type: data.system_type};
                  if (data.system_type == 'facebook') {
                      request.system_id = data.userID
                      request.access_token = data.accessToken
                  }
                  else if(data.system_type == 'google'){
                      request.id_token = data.id_token;
                  }
                  axios.post(`${process.env.VUE_APP_API_ENDPOINT}/external_login`, request).then(result => {
                      dispatch("client_side_login", result.data).then(result => {
                          if(state.user.new_user === true) gtag('event', 'sign_up', {'method': 'Native'});
                          resolve();
                      }).catch(e => {
                          reject(e);
                      });
                  }).catch(e => {
                      reject(e);
                  });
              } else {
                  console.log(data);
                  if (data.system_type == 'facebook' &&
                      (!state.user.facebook ||
                          !state.user.facebook.access_token ||
                          state.user.facebook.access_token != data.accessToken)) {
                      API.post('gtf', '/external_system',
                          {
                              body: {system_type: 'facebook', system_id: data.userID, access_token: data.accessToken},
                              headers: {'Content-Type': 'application/json'}
                          }).then(response => {
                              commit('set_facebook', {access_token: data.accessToken, user_id: data.userID})
                              resolve();
                      });
                  }
                  if (data.system_type == 'facebook') {
                      commit('set_facebook', {access_token: data.accessToken, user_id: data.userID})
                      resolve();
                  }
                  if (data.system_type == 'google' && !state.user.google) {
                      API.post('gtf', '/external_system',
                          {
                              body: {system_type: 'google', id_token: data.id_token},
                              headers: {'Content-Type': 'application/json'}
                          }).then(response => {
                              resolve();
                          }).catch(e => {
                              reject(e);
                          });
                  }
                  else {
                      resolve();
                  }
              }
          });
      },
      get_photos({commit, state, dispatch}, {person_id, event_id}){
          if(!event_id && state.event) event_id = state.event.id;
          return new Promise(function(resolve, reject) {
              if (state.image_more_data) {
                  let pathparts = []

                  if (person_id) {
                      pathparts.push('person_id=' + person_id);
                  }
                  pathparts.push('page=' + state.image_page);
                  commit('next_photo_page');

                  commit('setImageFilterPerson', person_id);
                  API.get('gte', '/event/' + event_id + '/photos' + (pathparts.length == 0 ? '' : '?' + pathparts.join('&'))).then((response) => {
                      commit('addimages', response.images);
                      if (!response.images.length) commit('no_more_image_data');
                      if (response.person) {
                          commit('setPerson', response.person)
                      }
                      resolve();
                  });
              }
              else{
                  resolve();
              }
          });
      },
      get_events({commit, state, dispatch}) {
          return new Promise(function(resolve, reject) {
              if (state.events_more_data) {
                  API.get('gte', '/events?page=' + state.events_page).then((response) => {
                      commit('next_event_page');
                      commit('addEvents', response);
                      if (!response.length) commit('no_more_event_data');
                      resolve();
                  });
              }
              else{
                  resolve();
              }
          });
      },
      processing_complete({state, commit}, data){
          commit('addPhoto', data.image)
          commit('setImage', data.image)
          gtag('event', 'processing_complete', {'event_category': 'images'});
          commit('set_complete', data.image);
      },

  }
});
