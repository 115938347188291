<template>
    <v-container>
        <Loading :loading="loading"></Loading>
        <v-layout justify-center>
            <v-flex sm6>
                <v-card>
                    <v-card-title><h1>Recover Password</h1></v-card-title>
                    <v-card-text>
                        <v-form v-on:submit.prevent="forgotMethod">
                            <v-text-field v-model="login_id" prepend-icon="mdi-account" name="login" label="Login (email or phone number)" type="text"></v-text-field>
                            <v-btn type="submit">Forgot Password</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import Loading from '../components/Loading'
    import axios from 'axios'

    export default {
        components: {
            Loading
        },
        data: function(){
          return {
            login_id: null,
            loading: false
          }
        },
        name: "ForgotPassword",
        methods: {
          forgotMethod: function(){
            this.loading = true;
            axios.post(`${process.env.VUE_APP_API_ENDPOINT}/forgot_password`,
                {
                    login_id: this.login_id
                }
            ).then(response => {
               this.$router.push({path:'/verify/' + this.login_id,
                    query: {redirect_to: this.$route.query.redirect_to}
                });
                this.loading=false
            }).catch(()=>{
                this.loading=false
            })
          }
        }
    }
</script>

<style scoped>

</style>
