<template>
     <canvas ref="canvas_tag" :style="page_size()"></canvas>
</template>

<script>
import UTIF from 'utif'
import FileType from 'file-type/browser'
import decode from 'heic-decode'

export default {
    props: {
      'src': String,
      'photo': Object,
      'full': Boolean
    },
    name: "Canvasimage",
    methods:{
        page_size(){
            return {
                display: 'block',
                'max-height': this.full ? '100vh' : 'calc(100vh - 150px)',
                'max-width': '100%',
                'min-width': '150px'
            }
        },
        nocors(){
            let self = this;
            let img = new Image();
            if(!this.$refs.canvas_tag){
                //self.$nextTick(function () {
                //     self.display();
                //});
                console.debug("No Canvas Tag")
            }
            img.onload = function () {
                let  width = img.width,
                    height = img.height,
                    canvas = self.$refs.canvas_tag;


                let ctx = canvas.getContext("2d");

                // set proper canvas dimensions before transform & export
                if (4 < self.photo.orientation && self.photo.orientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }

                // transform context before drawing image
                switch (self.photo.orientation) {
                    case 2:
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        ctx.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        ctx.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        ctx.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        ctx.transform(0, 1, -1, 0, height, 0);
                        break;
                    case 7:
                        ctx.transform(0, -1, -1, 0, height, width);
                        break;
                    case 8:
                        ctx.transform(0, -1, 1, 0, 0, width);
                        break;
                    default:
                        break;
                }

                // draw image
                ctx.drawImage(img, 0, 0);
            }
            img.src = this.src;
        },
        tif_file(e){
            let self = this;
            let canvas = this.$refs.canvas_tag;
            let ctx = canvas.getContext('2d');
            var ifds = UTIF.decode(e.target.response);
            UTIF.decodeImage(e.target.response, ifds[0])
            var rgba  = UTIF.toRGBA8(ifds[0]);  // Uint8Array with RGBA pixels

            let width = ifds[0].width;
            let height = ifds[0].height;
            canvas.width = width;
            canvas.height = height;
            ctx.putImageData(new ImageData(Uint8ClampedArray.from(rgba), ifds[0].width, ifds[0].height), 0, 0);
        },
        heic_file(e){
            let self = this;
            let canvas = this.$refs.canvas_tag;
            let ctx = canvas.getContext('2d');
            const buffer = Buffer.from(e.target.response)
            decode({ buffer: buffer}).then(heic => {
                console.log(heic)
                canvas.width = heic.width;
                canvas.height = heic.height;
                ctx.putImageData(new ImageData(new Uint8ClampedArray(heic.data), heic.width, heic.height), 0, 0);
            });
        },
        default_file(e){
            let self = this;
            let canvas = this.$refs.canvas_tag;
            let ctx = canvas.getContext('2d');
            let img = new Image();
            img.onload = function () {
                let  width = img.width,
                    height = img.height,
                    canvas = self.$refs.canvas_tag;


                let ctx = canvas.getContext("2d");
                canvas.width = width;
                canvas.height = height;

                // set proper canvas dimensions before transform & export
                /*if (4 < self.photo.orientation && self.photo.orientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                }*/

                // transform context before drawing image
                /*switch (self.photo.orientation) {
                    case 2:
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        ctx.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        ctx.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        ctx.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        ctx.transform(0, 1, -1, 0, height, 0);
                        break;
                    case 7:
                        ctx.transform(0, -1, -1, 0, height, width);
                        break;
                    case 8:
                        ctx.transform(0, -1, 1, 0, 0, width);
                        break;
                    default:
                        break;
                }
*/
                // draw image
                ctx.drawImage(img, 0, 0);
            }
            var blob = new Blob([e.target.response]);
            img.src = URL.createObjectURL(blob);;
        },
        load(){
            let self = this;
            let canvas = this.$refs.canvas_tag;
            let ctx = canvas.getContext('2d');

            function imgLoaded(e) {
                if(e.target.status == 403){
                  console.log('Handle Credentials Timeout issue');
                  window.location.reload();
                }
                (async () => {
                    let filetype = await FileType.fromBuffer(e.target.response)
                    switch (filetype.mime) {
                      case "image/tiff":
                        self.tif_file(e);
                        break;
                      case "image/heic":
                        self.heic_file(e);
                        break;
                      default:
                        self.default_file(e);
                    }
                })();



                /*// set proper canvas dimensions before transform & export
                if (4 < self.photo.orientation && self.photo.orientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }

                // transform context before drawing image
                switch (self.photo.orientation) {
                    case 2:
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        ctx.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        ctx.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        ctx.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        ctx.transform(0, 1, -1, 0, height, 0);
                        break;
                    case 7:
                        ctx.transform(0, -1, -1, 0, height, width);
                        break;
                    case 8:
                        ctx.transform(0, -1, 1, 0, 0, width);
                        break;
                    default:
                        break;
                }*/

                // draw image
                //ctx.drawImage(img, 0, 0);
            }

            //Use old system when new one won't work
            if(this.photo.system == 'google'){
              this.nocors();
            }
            else {
              var xhr = new XMLHttpRequest();
              xhr.open("GET", self.src);
              xhr.responseType = "arraybuffer";
              xhr.onload = imgLoaded;
              xhr.send();
            }

        }
    },
    watch: {
      src: function(){
        this.load()
      }
    },
    mounted(){
      this.load()
    }
}
</script>

<style scoped>

</style>
