<template>
    <v-container v-if="event" fluid>
      <v-app-bar app color="primary" dark class="d-print-none">
        <router-link :to="$store.state.user ? '/events' : '/'"><img src="/gtf-logo-48.png"></router-link>
        <v-toolbar-title>{{ event.name }}<br/><small>{{ $pretty_date(event.start_date) }}
          <span v-if="event.end_date"> - {{ $pretty_date(event.end_date) }}</span></small></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn :to="'/event/' + event.id + '/photos'" color="primary" elevation="0">
              <span>Photos&nbsp;</span>
              <v-icon>mdi-filmstrip</v-icon>
          </v-btn>
          <v-btn
              v-if="$store.state.images.length"
              :to="'/event/' + event.id + '/photo/' + $store.state.images[0].id + '?slideshow&index=0'"
              color="primary" elevation="0">
              <span>Slideshow&nbsp;</span>
              <v-icon>mdi-presentation-play</v-icon>
          </v-btn>
          <v-btn :to="'/event/' + event.id + '/people'" color="primary" elevation="0">
              <span>People&nbsp;</span>
              <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
          <v-btn v-if="can_upload && (event.payments || event.organization_id || ($store.state.user && event.author_id == $store.state.user.user_id))" tag="label" for="file" @click.capture="check_upload" color="primary" elevation="0">
              <span>Upload&nbsp;</span>
              <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn v-if="event.download_url" :href="event.download_url" color="primary" elevation="0">
              <span>Download&nbsp;</span>
              <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
          <v-btn v-if="can_upload" :to="`/event/${event.id}/info`" color="primary" elevation="0">
            <span>Instructions</span>
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
          <v-btn :to="'/event/' + event.id + '/settings'" color="primary" v-if="$store.state.user && event.author_id == $store.state.user.user_id" elevation="0">
              <span>Settings&nbsp;</span>
              <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <UserMenu></UserMenu>
        <template v-slot:extension v-if="$store.state.image_filter_person && $route.name == 'EventPhotos' && $store.state.person">
            <v-avatar size="100" color="blue-grey darken-4" style="border-width: 5px; border-style: solid; position: relative; top: 32px;">
              <img v-if="$store.state.person.thumbnail_url" :src="$store.state.person.thumbnail_url"></img>
              <v-icon size="64px" v-if="!$store.state.person.thumbnail_url">mdi-face</v-icon>
            </v-avatar>
            {{ $store.state.person.name }}
        </template>
      </v-app-bar>
      <v-container v-if="!event.payments && !event.organization_id && $store.state.user && event.author_id == $store.state.user.user_id">
        <v-row justify="center">
          <v-col sm="12" md="4">
            You may upload photos to test the system, but payment is required for guests to upload
            <br>
            <p class="text-center">
              <v-btn
                color="primary"
                @click="buy_now('single')"
                x-large
              >
                Buy Now
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-container>
      <router-view></router-view>
      <v-bottom-navigation app v-if="$vuetify.breakpoint.smAndDown" color="primary">
          <v-btn :to="'/event/' + event.id + '/photos'">
              <span>Photos</span>
              <v-icon>mdi-filmstrip</v-icon>
          </v-btn>
          <v-btn
              v-if="$store.state.images.length"
              :to="'/event/' + event.id + '/photo/' + $store.state.images[0].id + '?slideshow&index=0'">
              <span>Slideshow</span>
              <v-icon>mdi-presentation-play</v-icon>
          </v-btn>
          <v-btn :to="'/event/' + event.id + '/people'">
              <span>People</span>
              <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
          <v-btn v-if="can_upload && (event.payments || event.organization_id || ($store.state.user && event.author_id == $store.state.user.user_id))" tag="label" for="file" @click.capture="check_upload">
              <span>Upload</span>
              <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
          <v-btn v-if="can_upload" :to="`/event/${event.id}/info`" color="primary" elevation="0">
            <span>Instructions</span>
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>

          <v-btn :to="'/event/' + event.id + '/settings'" v-if="$store.state.user && event.author_id == $store.state.user.user_id">
              <span>Settings</span>
              <v-icon>mdi-cog</v-icon>
          </v-btn>
      </v-bottom-navigation>
        <Vue-Upload-Component
            ref="upload"
            v-model="files"
            @input-file="inputFile"
            @input-filter="inputFilter"
            accept="image/*,application/pdf,video/*,.heic"
            drop="body"
            style="cursor: pointer"
            multiple
            >
        </Vue-Upload-Component>
                <v-bottom-sheet inset eager v-model="sheet" v-if="filevisible">
                  <template v-slot:activator="{ on }">
                    <v-btn fab color="#00FFFF" fixed bottom right v-on="on">
                      <v-badge :value="active_count > 0" :content="active_count">
                          <v-icon>mdi-cloud-upload</v-icon>
                      </v-badge>
                      <v-progress-circular
                        v-if="active_count"
                        style="position: absolute"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-btn>
                  </template>
                    <v-card>
                        <v-list>
                            <v-list-item v-for="(file, name) in $store.state.processing" :key="name">
                                <v-list-item-avatar>
                                    <img v-if="file.thumbnail" :src="'data:image/jpeg;base64, ' + file.thumbnail">
                                    <img v-else-if="file.src" :src="file.src">
                                    <img v-else-if="file.type.startsWith('image') && file.blob" :src="file.blob">
                                    <img v-else-if="file.name.toLowerCase().endsWith('.pdf')" src="/pdf-file-format.png">
                                    <img v-else-if="file.type.startsWith('video')" src="/video-player.png">
                                    <img v-else src="/image.png">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    {{file.name}}<small v-if="file.size">{{file.size | formatSize}}</small>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span v-if="file.error"><v-icon color="error">mdi-cloud-alert</v-icon><v-btn @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})">Retry</v-btn></span>
                                    <span v-if="file.status=='processing'">Processing<v-progress-circular indeterminate></v-progress-circular></span>
                                    <span v-if="file.status=='complete'">Complete<v-icon color="success">mdi-cloud-check</v-icon></span>
                                    <span v-if="file.status=='uploading'">Uploading <v-progress-circular :value="file.progress"></v-progress-circular></span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-bottom-sheet>
    </v-container>
</template>

<script>
import API from '@aws-amplify/api';
import Auth from "@aws-amplify/auth";
import {DateTime} from 'luxon';
import Vue from 'vue';
import UserMenu from "../components/UserMenu";
import VueUploadComponent from 'vue-upload-component';
import axios from "axios";

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})

export default {
  name: "EventPage",
  components: {UserMenu, VueUploadComponent,},
  props: ["event_id"],
  data: () => ({
      files: [],
      completed: [],
      loading: false,
      ordering: false,
      sheet: false
  }),
  computed: {
    event(){
      return this.$store.state.event_dict[this.event_id]
    },
    active_count(){
        let count = 0;
        for(let [key, val] of Object.entries(this.$store.state.processing)){
            if(val.status == 'uploading' || val.status == 'processing'){
                count++;
            }
        }
        return count;
    },
    process_count(){
      let count = 0;
      for(let [key, val] of Object.entries(this.$store.state.processing)){
          if(val.status == 'processing'){
              count++;
          }
      }
      return count;
    },
    success_count(){
      let count = 0;
      for(let [key, val] of Object.entries(this.$store.state.processing)){
          if(val.status == 'complete'){
              count++;
          }
      }
      return count;
    },
    filevisible(){
        if(this.files.length) return true;
        if(this.$store.getters.is_processing || this.$store.getters.is_complete ) return true;
        return false;
    },
    can_upload(){
      return DateTime.fromISO(this.event.accept_until) > DateTime.now();
    }
  },
  mounted(){
    if(!(this.event_id in this.$store.state.event_dict)){
      API.get('gte', '/event/' + this.event_id).then((response) => {
        this.$store.commit('setEvent', response);
      });
    }
    else {
      this.$store.commit('setEvent', this.$store.state.event_dict[this.event_id])
    }
  },
  methods:{
        inputFile: function (newFile, oldFile) {
          let self = this;
          if (newFile && oldFile && !newFile.active && oldFile.active) {
            // Get response data
            console.log('response', newFile.response);
            if (newFile.xhr) {
              //  Get the response status code
              console.log('status', newFile.xhr.status)
            }
          }
          if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
              console.log("File Added");
              let key = this.$store.state.event.author_id + '/' + newFile.name
              if(!self.$store.state.processing.hasOwnProperty(key)) {
                self.$store.commit('set_uploading', {
                  key: key,
                  file: newFile
                });
                axios.post(`${process.env.VUE_APP_API_ENDPOINT}/upload_url`,
                {
                    key: key,
                    contenttype: newFile.type

                }).then(response => {
                  newFile.system = response.data.system
                  newFile.putAction = response.data.url
                  if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true;
                  }
                });
              }
          }
          if (newFile && oldFile && newFile.success !== oldFile.success) {
              let key = this.$store.state.event.author_id + '/' + newFile.name
              console.log('success', newFile.success, newFile)
              gtag('event', 'upload_complete', {'event_category': 'images'});
              Auth.currentCredentials().then(credentials => {
                  let sqs = new AWS.SQS({
                      credentials: Auth.essentialCredentials(credentials),
                      region: 'us-east-2'
                  });
                  sqs.sendMessage({
                      QueueUrl: process.env.VUE_APP_IMAGE_PROCESSING_QUEUE,
                      MessageBody: JSON.stringify([{key: key}]),
                      MessageAttributes: {
                          'sub': {
                              DataType: 'String',
                              StringValue: this.$store.state.event.author_id.toString()
                          },
                          'system_type': {
                              DataType: 'String',
                              StringValue: newFile.system
                          },
                          'event_id': {
                              DataType: 'String',
                              StringValue: this.event_id
                          }
                      }
                  }, function (err, data) {
                      self.$store.commit('set_processing', {key: key, file: newFile});
                  })
            });
          }
        },
        /**
         * Pretreatment
         * @param  newFile Object|undefined      Read and write
         * @param  oldFile Object|undefined      Read only
         * @param  prevent Function              Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
          if (newFile && !oldFile) {
            // Filter non-image file
            if (!/\.(jpeg|jpe|jpg|gif|png|webp|raw|tif|tiff|heic|pdf|mp4|webm|mpg|mp2|mpeg|mpe|mpv|ogg|m4v|m4p|avi|wmv|mov|qt|flv|avchd|ged)$/i.test(newFile.name)) {
              return prevent()
            }

          }
          //Check if we can upload what we are trying to upload

          // Create a blob field
          newFile.blob = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            //getOrientation(newFile.file, function(orientation) {
            //    resetOrientation(URL.createObjectURL(newFile.file), orientation, function(output){
                    newFile.blob = URL.createObjectURL(newFile.file);
            //    })
            //});
          }

        },
        check_upload(event){
          return true;
        },
      buy_now(plan_id) {
        if (!this.$store.state.user) {
          this.$router.push({path: '/signup', query: {'redirect_to': '/pricing'}});
        }
        let event_id = this.event.id;
        if (plan_id == 'single') {
          API.get('gte', `/stripe_session?plan=${plan_id}&event_id=${event_id}`).then(result => {
            console.log(result)
            const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
            stripe.redirectToCheckout({
              // Make the id field from the Checkout Session creation API response
              // available to this file, so you can provide it as parameter here
              // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
              sessionId: result.session_id
            }).then(function (result) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
            });
          })
        } else {
          this.$router.push(`/company?event_id=${this.event.id}`)
        }
      }

  },
}
</script>

<style scoped>

</style>
