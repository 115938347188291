<template>
  <base-section
    id="pricing-plan"
    class="text-center"
  >
    <base-section-heading title="Simple all inclusive pricing">
      Planning an event is hard. We try to make things simple. Our low cost all in one plan is perfect for any event.
    </base-section-heading>

    <div class="pa-8" />

    <v-container>
      <v-row justify="center">
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          cols="12"
          md="4"
        >
          <price-card v-bind="plan" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPricingPlan',

    components: {
      PriceCard: () => import('@/components/PriceCard'),
    },

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      features: [
        'Unlimited Storage',
        'Unlimited Guests',
        'Email, Text Message, Twitter Hashtag, Web Upload',
        'Try it Free'
      ],
      internalPlans: [
        {
          title: 'Event',
          subtitle: 'Simple all inclusive pricing',
          price: 39.99
        }
      ],
      interval: 0,
    }),

    computed: {
      plans () {
        return this.internalPlans.map(plan => {
          return {
            ...plan,
            features: this.features,
            interval: 'Event',
            title: plan.title,
            subtitle: plan.subtitle,
            price: plan.price,
          }
        })
      },
    },
  }
</script>
