<template>
  <section id="hero">
    <v-img
      :max-height="minHeight"
      :src="require('@/assets/sharing.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container :class="['fill-height', 'px-4', $vuetify.breakpoint.smAndDown ? '' : 'py-12']">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading
            size="text-h2"
            title="Gather all your event pictures, in one place."
            weight="medium"
          />

          <base-body v-if="$vuetify.breakpoint.smAndUp">
            We make it easy for guests - just text a number, scan a QR code, or tweet.
          </base-body>

          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap"
          >
            <base-btn to="/signup">
              Get Started Now
            </base-btn>

          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
