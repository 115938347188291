import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Amplify from '@aws-amplify/core';
import aws_exports from './aws-exports';
import VueGapi from 'vue-gapi'
import VueNativeSock from "vue-native-websocket";
import './plugins/base'
import { DateTime } from "luxon";

Vue.config.productionTip = false

Amplify.configure(aws_exports);

Vue.use(VueGapi, {
  apiKey: 'AIzaSyCZb50DtgtM_57W7qsD0_zTG9w2OCg4kIQ',
  clientId: "875420814877-scfr5qdjmtugers3e184nimarllndqi2.apps.googleusercontent.com",
  discoveryDocs: [],
  scope: 'profile email',
})
Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_URL,
    {
        store: store,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000
    });

// Add this as a Vue plugin
Vue.use((Vue) => {
  Vue.prototype.$bubble = function $bubble(eventName, ...args) {
    // Emit the event on all parent components
    let component = this;
    do {
      component.$emit(eventName, ...args);
      component = component.$parent;
    } while (component);
  };
});

Vue.prototype.$pretty_date = function(ds){
      let date = DateTime.fromISO(ds);
      return date.toFormat('MM/dd/yyyy')
    }


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
