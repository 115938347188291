<template>
    <v-card height="100%">
        <v-form>
            <Loading :loading="loading"></Loading>
            <v-card-title primary-title><div class="headline">Login</div></v-card-title>
            <v-card-text>
                    <ThirdPartyLogin></ThirdPartyLogin>
                    <v-text-field
                        v-model="login_id"
                        prepend-icon="mdi-account"
                        name="login"
                        label="Login (email or phone number)"
                        type="text"
                        :error-messages="errors"
                        placeholder=" "
                        persistent-placeholder
                        autocomplete="username"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        prepend-icon="mdi-lock-question"
                        name="password"
                        label="Password"
                        id="password"
                        type="password"
                        placeholder=" "
                        persistent-placeholder
                        messages="Password is optional, You will be sent a one-time password code to login. If you have a valid one time code you may enter it here"
                        autocomplete="current-password"
                    ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" type="submit" @click.prevent="login()">Login</v-btn>
                <v-btn @click="forgot_password()">Forgot Password</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import Loading from './Loading'
    import axios from 'axios'
    import ThirdPartyLogin from '@/components/ThirdPartyLogin'

    export default {
        components: {
          ThirdPartyLogin,
            Loading
        },
        name: "Login",
        data: function() {
            return {
                login_id: null,
                password: null,
                loading: false,
                errors: null,
                auth2: null
            }
        },
        mounted() {
            //gapi.signin2.render('google-signin-button', {
            //    onsuccess: this.onSignIn
            //})
        },
        methods:{
            login(){
                let self = this;
                this.loading = true;
                axios.post(`${process.env.VUE_APP_API_ENDPOINT}/login`,
                    {
                        login_id: this.login_id,
                        password: this.password
                    }
                ).then(response => {
                    if(response.data.verify_required && !(self.$route.query["redirect_to"] && self.$route.query["redirect_to"].includes("/verify/"))){
                       this.$router.push({path:'/verify/' + this.login_id,
                            query: {redirect_to: this.$route.query.redirect_to}
                        });
                    }
                    else {
                        this.$store.dispatch("client_side_login", response.data).then(() => {
                            this.loading = false;
                            gtag('event', 'login', {'method': 'Native'});
                            if (self.$route.query["redirect_to"] && self.$route.query["redirect_to"] != 'undefined') {
                                self.$router.push(self.$route.query["redirect_to"])
                            } else {
                                self.$router.push("/events")
                            }
                        })
                    }
                }).catch((error)=>{
                    console.log(error)
                    this.errors = ["Invalid User or Password"];
                    this.loading=false
                })
            },
            forgot_password(){
                this.$router.push({path:'/forgot_password',
                            query: {redirect_to: this.$route.query.redirect_to}
                        });
            }
        }
    }
</script>

<style scoped>

</style>
