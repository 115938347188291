<template>
  <v-toolbar-items>
     <v-menu v-if="user">
         <template v-slot:activator="{on }">
            <v-btn v-on="on" color="primary" elevation="0">
                <v-avatar v-if="user.person">
                    <img :src="user.person.person_thumbnail_url" v-if="user.person.person_thumbnail_url">
                    <v-icon large v-if="!user.person.person_thumbnail_url">mdi-face</v-icon>
                </v-avatar>
                <v-icon large v-if="!user.person">mdi-account</v-icon>
              <span class="d-none d-sm-inline">{{ user.username }}<span v-if="user.organization"><br>{{ user.organization.name }}</span></span>
                <v-icon>mdi-menu-down</v-icon>
            </v-btn>
         </template>
          <v-list>
            <v-list-item v-if="user.roles.includes('Superuser')" to="/users">
              <v-list-item-title>Superuser - Users</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user.roles.includes('Superuser')" to="/organizations">
              <v-list-item-title>Superuser - Organizations</v-list-item-title>
            </v-list-item>
            <v-list-item to="/profile">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="user.organization" :to="`/company/${user.organization.id}`">
              <v-list-item-title>Company Info</v-list-item-title>
            </v-list-item>
            <v-list-item to="/notifications">
              <v-list-item-title>Notification Settings</v-list-item-title>
            </v-list-item>
            <v-list-item to="/billing">
              <v-list-item-title>Billing</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>

          </v-list>
     </v-menu>
     <v-btn text v-if="!user" to="/login">
         Login
     </v-btn>
     <v-btn v-if="!user" to="/signup">
         Try it Free!
     </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  name: "UserMenu",
  computed:{
    user(){
      return this.$store.state.user
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  }
}
</script>

<style scoped>

</style>
