<template>
  <div class="container">
    <h1>User Profile</h1>
    <v-form v-on:submit.prevent="save_profile">
        <v-text-field label="Username" v-model="user.username"></v-text-field>
        <v-text-field label="Email Address" v-model="user.email"></v-text-field>
        <v-text-field label="Phone Number" v-model="user.phone_number"></v-text-field>
        <v-text-field
            v-model="password"
            label="Password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            :rules="password_rules"
            @click:append="show1 = !show1"
        ></v-text-field>
        <v-text-field
            v-model="verify_password"
            label="Verify Password"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            :rules="password_match"
            @click:append="show2 = !show2"
        ></v-text-field>
        <v-btn type="submit">Save</v-btn>
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
            <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on">Delete Account</v-btn>
            </template>
            <v-card>
            <v-card-title class="headline">Delete your account?</v-card-title>

            <v-card-text>
              Selecting yes will send an email to your email account of record. You must click the link in that email to delete your account.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                @click="dialog = false"
              >
                No
              </v-btn>

              <v-btn
                @click="delete_request"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-form>
  </div>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        data: function(){
            return {
                dialog: false,
                errors: [],
                loading: false,
                show1: false,
                show2: false,
                password: null,
                verify_password: null,
                password_rules: [
                    v => v && v.length >= 8 || 'Password must be at least 8 characters long',
                    v => /[0-9]/.test(v) || 'Password must contain at least 1 digit',
                    v => /[a-z]/.test(v) || 'Password must contain at least 1 lowercase letter',
                    v => /[A-Z]/.test(v) || 'Password must contain at least 1 uppercase letter'
                ],
                emailRules: [
                        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
                      ],
                password_match: [
                    v => v == this.password || 'Passwords must match'
                ]
            }
        },
        name: "Profile",
        filters: {
            formatBytes: function(bytes, decimals = 2) {
                if (bytes === 0) return '0 Bytes';

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            }
        },
        computed:{
            user: function() {
                return this.$store.state.user;
            },
            total_space: function(){
                return this.user.free_space + this.user.paid_space + this.user.plan.included_storage
            },
            available_space: function(){
                return this.total_space - this.user.space_used
            }
        },
        mounted: function(){
        },
        methods: {
            save_profile: function(){
                API.post('gte', '/user/' + this.user.user_id, {
                    body: {
                        'username': this.user.username,
                        'email': this.user.email,
                        'phone_number': this.user.phone_number,
                        'password': this.password
                    },
                    headers: {'Content-Type': 'application/json'}
                })
            },
            delete_request: function(){
                this.dialog = false;
                API.post('gte', '/delete_request', {
                    body: this.user,
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                });
            },
        }
    }
</script>

<style scoped>

</style>
