<template>
  <div>
    <SectionHero></SectionHero>
    <SectionAboutOurProduct></SectionAboutOurProduct>
    <SectionPricingPlan></SectionPricingPlan>
    <base-section>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="6" class="text-center">
          <base-subheading
              size="display-1"
              title="Perfect for any type of event"
              space="0"
              style="padding-bottom: 20px;"
            />
          <v-row>
            <v-col cols="12" md="6">
              <base-list-item text="Weddings" />
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Birthdays" />
            </v-col>
            <v-col cols="12" md="6">
                <base-list-item text="Family Reunions"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Corporate Events"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Graduations"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Baby Showers"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Family Reunions"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Bar/Bat Mitzvahs"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Anniversaries"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Graduations"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Quinceañeras"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Concerts"/>
            </v-col>
            <v-col cols="12" md="6">
              <base-list-item text="Conferences"/>
            </v-col>
          </v-row>
          <v-btn to="/signup" x-large color="primary">Sign up Now</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
</div>
</template>

<script>
  import Login from "../components/Login";
  import SignUp from "../components/SignUp";
  import SectionHero from "../components/Hero";
  import SectionAboutOurProduct from "../components/AboutOurProduct";
  import SectionPricingPlan from "../components/PricingPlan";
  import BaseListItem from "../components/base/ListItem";
  import BaseHeading from "../components/base/Heading";
  import BaseSection from "../components/base/Section";

  export default {
    name: 'Home',

    components: {
      BaseSection,
      BaseHeading,
      BaseListItem,
      SectionPricingPlan,
      SectionAboutOurProduct,
      SignUp,
      Login,
      SectionHero
    }
  }
</script>
