<template>
    <v-card class="text-center">
        <v-avatar size="100"  color="blue-grey darken-4" @click="nav()" style="border-width: 5px; border-style: solid; cursor: pointer;">
            <img v-if="person.thumbnail_url" :src="person.thumbnail_url"></img>
            <v-icon size="64px" v-if="!person.thumbnail_url">mdi-face</v-icon>
        </v-avatar><br>
        <v-card-text>
          <PersonSelector :event_id="event_id" :person="person" @change="save_person"></PersonSelector>
          {{ person.photos }} picture{{ person.photos==1 ? '' : 's' }}<br>
        </v-card-text>
    </v-card>
</template>

<script>
import API from "@aws-amplify/api";
import PersonSelector from "./PersonSelector";

export default {
  name: "PeopleCard",
  components: {PersonSelector},
  props: ['person', 'event_id'],
  methods: {
    nav(){
      this.$router.push('/event/' + this.event_id + '/photos?person=' + this.person.id)
    },
    save_person(event){
      if(typeof event !== 'object') {
        this.person.name = event;
        API.post('gte', '/person/' + this.person.id, {
          body: this.person,
          headers: {'Content-Type': 'application/json'}
        });
      }
      else{
        if (event.id != this.person.id) {
          API.post('gte', '/person/' + event.id + '/merge/' + this.person.id, {
          body: this.person,
          headers: {'Content-Type': 'application/json'}
        }).then(response => {
          this.$emit('delete_person')
        });
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
