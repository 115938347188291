<template>
  <base-section id="about-our-product">
    <base-section-heading
      icon="mdi-star-circle-outline"
      title="Gather the Event Features"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Upload Options',
          subtitle: 'Send Photos',
          text: 'Guests can upload their photos by visiting your event page, emailing your unique email address, texting the photos, or tweeting the photos with a hashtag you define.',
          callout: '',
        },
        {
          title: 'Simple Process',
          subtitle: 'Easy to use',
          text: 'The event host creates an event. Give your guests the email address, phone number, hashtag, and/or url. We collect the photos. At the end of the event download all the photos in a single zip file.',
          callout: '',
        },
        {
          title: 'Live Slideshow',
          subtitle: 'Excite your Guests',
          text: 'Use our live slideshow to project the photos that your guests have uploaded live during the event.',
          callout: '',
        },
      ],
    }),
  }
</script>
