import axios from 'axios'
import Auth from '@aws-amplify/auth';
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome'
import Events from '../views/Events'
import Verify from "../views/Verify";
import ForgotPassword from "../views/ForgotPassword";
import UpdatePassword from "../views/UpdatePassword";
import EventPage from "../views/EventPage";
import EventPhotos from "../views/EventPhotos";
import EventPeople from "../views/EventPeople";
import EventSettings from "../views/EventSettings";
import Lightbox from "../views/Lightbox";
import Pricing from "../views/Pricing";
import Profile from "../views/Profile";
import Billing from "../views/Billing";
import PaySuccess from "../views/PaySuccess";
import CompanyInfo from "../views/CompanyInfo";
import EventInfo from "../views/EventInfo";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: { requiresAuth: true},
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "about" */ '../views/Privacy')
    },
    {
        path: '/terms',
        name: 'Terms',
        component: () => import(/* webpackChunkName: "about" */ '../views/Terms')
    },
    {
        path: '/signup',
        name: 'signup',
        component: Welcome,
        props: function(route){
            return {
                signup: true,
                login: false
            }
        }
    },
    {
      path: '/login',
      name: 'login',
      component: Welcome,
      props: function(route){
          return {
              signup: false,
              login: true
          }
      }
    },
    {
        path: '/verify/:id',
        name: 'Verify',
        component: Verify,
        props: function(route){
            return {
                login_id: route.params.id,
                verify_code: route.query.code
            }
        }
    },
    {
      path: '/forgot_password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/update_password',
      name: 'UpdatePassword',
      component: UpdatePassword,
      meta: { requiresAuth: true},
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: { requiresAuth: true}
    },
    {
      path: '/billing',
      name: 'Billing',
      component: Billing,
      meta: { requiresAuth: true}
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing
    },
    {
        path: '/company/:organization_id',
        name: 'CompanyInfo',
        component: CompanyInfo,
        meta: { requiresAuth: true},
        props: function(route) {
            return {
                organization_id: route.params.organization_id
            }
        }
    },
    {
        path: '/company',
        name: 'NewCompany',
        component: CompanyInfo,
        meta: { requiresAuth: true},
        props: function(route) {
            return {
                organization_id: null
            }
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "superuser" */ '../views/Users.vue'),
        meta: { requiresAuth: true, requiresSuperuser: true}
    },
    {
        path: '/organizations',
        name: 'Organizations',
        component: () => import(/* webpackChunkName: "superuser" */ '../views/Organizations.vue'),
        meta: { requiresAuth: true, requiresSuperuser: true}
    },
    {
        path: '/organization/:organization_id',
        name: 'Organization',
        component: () => import(/* webpackChunkName: "superuser" */ '../views/CompanyDetails.vue'),
        meta: { requiresAuth: true, requiresSuperuser: true},
        props: true
    },
    {
        path: '/user/:user_id',
        name: 'User',
        component: () => import(/* webpackChunkName: "superuser" */ '../views/UserDetails.vue'),
        meta: { requiresAuth: true, requiresSuperuser: true},
        props: true
    },
    {
        path: '/event/:event_id/photo/:image_id',
        name: 'Lightbox',
        component: Lightbox,
        props: function(route){
          return {
              event_id: parseInt(route.params.event_id),
              image_id: parseInt(route.params.image_id),
              slideshow: route.query.slideshow !== undefined ? true : false,
              index: route.query.index !==undefined ? parseInt(route.query.index) : null
          }
        }
    },
    {
        path: '/event/:id',
        component: EventPage,
        props: function(route){
          return {
              event_id: route.params.id
          }
        },
        children: [
          {path: '', redirect: 'photos'},
          {
              path: 'photos',
              name: 'EventPhotos',
              component: EventPhotos,
              props: function(route) {
                  return {
                      event_id: route.params.id,
                      person_id: route.query.person
                  }
              }
          },
          {
              path: 'people',
              component: EventPeople,
              name: 'EventPeople',
              props: function(route) {
                  return {
                      event_id: route.params.id
                  }
              }
          },
          {
              path: 'settings',
              component: EventSettings,
              name: 'EventSettings',
              props: function(route) {
                  return {
                      event_id: route.params.id
                  }
              }
          },
          {
              path: 'pay_success',
              name: 'PaySuccess',
              component: PaySuccess,
              props: function(route) {
                  return {
                      event_id: route.params.id
                  }
              }
          },
          {
              path: 'info',
              name: 'EventInfo',
              component: EventInfo,
              props: function(route) {
                  return {
                      event_id: route.params.id
                  }
              }
          }
        ]
    },
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) =>{
    let debug = process.env.VUE_APP_ENV != 'production'
    if(to.matched.length) {
        gtag('config', 'G-YVZ3WNX0YV', { 'page_path': to.matched[to.matched.length - 1].path,
            page_title: to.name, debug_mode: debug
        });
    }
    else{
        gtag('config', 'G-YVZ3WNX0YV', { 'page_path': to.fullPath , page_title: to.name, debug_mode: debug});
    }
    gtag('event', 'page_view');
});


router.beforeEach(async(to, from, next) => {
    await Vue.nextTick();
    if (router.app.$store.state.user) {
        if(to.path == '/') return next({path: '/events'})
        return next();
    }
    let session_id2 = localStorage.getItem("session_id2");
    console.log(`Session_id2: ${session_id2}`);
    if(to.matched.some(record => record.meta.requiresAuth || session_id2 )) {
        if (!session_id2) {
            if(localStorage.getItem('has_account')) {
                    return next({path: '/', query: {redirect_to: to.fullPath}})
            }
            else{
                return next({path: '/', query: {redirect_to: to.fullPath}})
            }
        }
        Auth.currentCredentials().then(creds => {
            console.log(creds);
            if(!creds){
                localStorage.removeItem("session_id2");
                router.app.$store.commit('setUser', null);
                return next('/?redirect_to=' + to.fullPath)
            }
            Auth.currentAuthenticatedUser().then(user =>{
                if(!router.app.$store.state.user) {
                    router.app.$store.commit('setUser', user);
                }
                if(to.path == '/') return next({path: '/events'});
                return next();
            });
        }).catch(e => {
            axios.post(`${process.env.VUE_APP_API_ENDPOINT}/token`, {
                    session_id2: localStorage.getItem('session_id2')
            }).then(response => {
                console.log(response);
                router.app.$store.commit("setUser", response.data);
                localStorage.setItem('session_id2', response.data.session_id2);
                localStorage.setItem('has_account', true);
                if(router.app.$socket.isConnected) {
                    router.app.$socket.sendObj({
                        action: 'login',
                        session_id2: response.data.session_id2
                    });
                }

                response.data.aws.expires_at = 15 * 1000 + new Date().getTime();
                Auth.federatedSignIn('cognito-identity.amazonaws.com', response.data.aws, response.data).then(cred =>{
                    //Access AWS resources here
                    return next();
                });
            }).catch(error => {
                console.log(error)
                localStorage.removeItem("session_id2");
                router.app.$store.commit('setUser', null);
                return next({path: '/', query: {redirect_to: to.fullPath}})
            });
        });
    }
    else {
        return next();
    }
});

function refreshToken() {
    console.log("In refresh token");
    // refresh the token here and get the new token info
    // ......

    return new Promise(function(res, rej){
        axios.post(`${process.env.VUE_APP_API_ENDPOINT}/token`, {
                session_id2: localStorage.getItem('session_id2')
        }).then(response => {
            router.app.$store.commit("setUser", response.data);
            localStorage.setItem('session_id2', response.data.session_id2);
            localStorage.setItem('has_account', true);
            if(router.app.$store.state.socket.isConnected) {
                router.app.$socket.sendObj({
                    action: 'login',
                    session_id2: response.data.session_id2
                });
            }
            response.data.aws.expires_at = 15 * 1000 + new Date().getTime();
            res(response.data.aws);
        }).catch(error => {
                console.log(error)
                localStorage.removeItem("session_id2");
                router.app.$store.commit('setUser', null);
                window.location.assign('/login?redirect_to=' + window.location.pathname);
        });
    });
}

Auth.configure({refreshHandlers: {
        'cognito-identity.amazonaws.com': refreshToken
    }});

export default router;

