<template>
  <v-container>
    <h1>Company Details</h1>
    <v-form>
      <v-text-field v-model="name" label="Company Name"></v-text-field>
      <v-text-field v-model="email" label="Billing Email"></v-text-field>
      <v-text-field v-model="phone" label="Billing Phone"></v-text-field>
      <v-text-field v-model="address1" label="Address Line 1"></v-text-field>
      <v-text-field v-model="address2" label="Address Line 2"></v-text-field>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field v-model="city" label="City"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="state" label="State/Region"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="zip_code" label="Zip/Postal Code"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="country" label="Country"></v-text-field>
        </v-col>
      </v-row>
      <v-select :items="dates" label="Invoice Day" v-model="billing_day"></v-select>
      <v-select
          v-if="$store.state.user.roles.includes('Superuser')"
          :items="prices"
          label="Price Override"
          v-model="price_override"
          item-text="price"
          item-value="price_id"
      >
      </v-select>
      <v-btn color="primary" @click="save()">Save</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import API from "@aws-amplify/api";

export default {
  name: "CompanyInfo",
  props: ['organization_id'],
  data: () => ({
    dates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
    prices: [],
    stripe_customer: null,
    billing_day: null,
    name: null,
    email: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip_code: null,
    price_override: null,
    country: null
  }),
  methods: {
    save(){
        API.post('gte', '/company', {
          body: {
            name: this.name,
            email: this.email,
            phone: this.phone,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            zip_code: this.zip_code,
            country: this.country,
            id: this.organization_id,
            stripe_customer: this.stripe_customer,
            billing_day: this.billing_day,
            price_override: this.price_override,
            events: this.$route.query ? [this.$route.query.event_id] : null
          },
          headers: { 'Content-Type': 'application/json' }
        }).then(() => {
          if(this.$store.state.user.roles.includes('Superuser')){
            this.$router.push('/organizations')
          } else {
            this.$router.push('/events')
          }
        });
    }
  },
  mounted(){
    API.get('gte', '/prices/' + process.env.VUE_APP_COMPANY_PRODUCT).then((response) => {
      this.prices = response;
    });
    let organization_id = this.organization_id;
    if(this.$store.state.user.organization_id && !organization_id) organization_id = this.$store.state.user.organization_id;
    if(this.$store.state.event && this.$store.state.event.organization_id) organization_id = this.$store.state.event.organization_id
    if(organization_id) {
      API.get('gte', '/company/' + organization_id).then((response) => {
        this.name = response.name;
        this.email = response.email;
        this.phone = response.phone;
        this.address1 = response.address1;
        this.address2 = response.address2;
        this.city = response.city;
        this.state = response.state;
        this.zip_code = response.zip_code;
        this.count = response.country;
        this.stripe_customer = response.stripe_customer;
        this.billing_day = response.billing_day
        this.price_override = response.price_override
      });
    }
  }
}
</script>

<style scoped>

</style>
