<template>
  <v-form v-model="valid" ref="form">
    <v-text-field
        label="Event Name"
        v-model="name"
        :rules="[rules.required]"
    ></v-text-field>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            label="Event Date"
            prepend-icon="mdi-calendar"
            @click:prepend="menu=true"
            readonly
            v-bind="attrs"
            v-on="on"
            :rules="[rules.required]"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="dates"
            range
            no-title
            scrollable
            :min="min_date"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu=false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(dates)"
          >
            OK
          </v-btn>
        </v-date-picker>
    </v-menu>
  </v-form>
</template>

<script>
import API from '@aws-amplify/api';
import {DateTime} from 'luxon';

export default {
  name: "Event",
  data: () => ({
      name: null,
      valid: false,
      menu: false,
      dates: [],
      rules: {
          required: value => !!value || 'Required.'
      }
  }),
  computed: {
      dateRangeText () {
        return this.dates.join(' - ')
      },
      min_date(){
        return DateTime.now().toFormat('yyyy-MM-dd');
      }
  },
  methods: {
    submit(){
      if(this.$refs.form.validate()){
        API.post('gte', '/event', {
          body: {
            start_date: DateTime.fromISO(this.dates[0]).toISO(),
            end_date: DateTime.fromISO(this.dates[1]).toISO(),
            name: this.name,
            organization_id: this.$store.state.user.organization_id},
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          this.$store.commit('setEvent', response);
          this.$emit('save_event', response)
        });
        return true;
      }
    }
  }
}
</script>

<style scoped>

</style>
