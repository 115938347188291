<template>
  <div class="d-flex align-content-center flex-wrap justify-center" style="background-color: black; height: 100vh">
    <v-menu fixed top right value="true" :close-on-click="false">
      <v-btn fab @click="$router.back()"><v-icon>mdi-close</v-icon></v-btn>
      <v-dialog
        v-if="$store.state.user"
        v-model="dialog"
        max-width="290"
      >
          <template v-slot:activator="{ on }">
              <v-btn fab color="error" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <v-card>
          <v-card-title class="headline">Delete this image?</v-card-title>

          <v-card-text>
            Deleting this image will make it unavailable.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click="dialog = false"
              color="green darken-1"
            >
              No
            </v-btn>

            <v-btn
              color="red darken-1"
              @click="delete_file"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-menu>
      <transition :name="random_transition()" mode="out-in">
      <keep-alive>
        <CanvasImage
            v-if="photo && photo.image_url"
            :src="photo.image_url"
            :photo="photo" full
            :key="photo.image_url">
        </CanvasImage>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import API from "@aws-amplify/api";
import CanvasImage from "../components/CanvasImage";

export default {
  name: "Lightbox",
  props: ['event_id', 'image_id', 'slideshow', 'index'],
  components: {CanvasImage},
  data: () => ({
    timer: null,
    dialog: false,
    transitions: ['fade', 'slide', 'rotate']
  }),
  computed: {
      photo(){
        return this.$store.state.image_dict[this.image_id];
      }
  },
  methods:{
      random_transition(){
        return this.transitions[Math.floor(Math.random() * this.transitions.length)];
      },
      loadimage(image_id){
        if(this.$store.state.image_dict[image_id] === undefined ||
            this.$store.state.image_dict[image_id].image_url === undefined) {
          API.get('gte', '/event/' + this.event_id + '/photo/' + image_id).then((response) => {
            this.$store.commit('setImage', response)
          });
        }
      },
      ensure_photos: async function(index){
          while(this.$store.state.image_page <  Math.floor(parseInt(index) / 50)){
              await this.$store.dispatch('get_photos', {person_id: null, event_id: this.event_id});
          }
      },
      Next: function () {
          //this.loading = true;
          let new_index = this.index + 1
          if (new_index > this.$store.state.images.length -5 && this.$store.state.image_more_data)
          {
            this.$store.dispatch('get_photos', {person_id: null, event_id: this.event_id});
          }
          if (new_index >= this.$store.state.images.length)
          {
            //First need to check if more images
            this.$router.replace({path: `/event/${this.event_id}/info?slideshow`});
          }
          this.ensure_photos(new_index).then(()=> {
            this.loadimage(this.$store.state.images[new_index].id)
            this.$router.replace({
              path: '/event/' + this.event_id + '/photo/' + this.$store.state.images[new_index].id,
              query: {
                slideshow: true,
                index: new_index
              }
            });
          });
      },
      delete_file(){
          API.del('gte', '/photo/' + this.photo.id)
          this.$router.replace('/event/' + this.event_id + '/photos');
          this.$store.commit('removeImage', this.photo);
      }
  },
  mounted(){
    this.loadimage(this.image_id);
    if(this.slideshow) {
      if (!this.$store.state.images.length) {
        this.$store.dispatch('get_photos', {person_id: null, event_id: this.event_id});
      }
      this.timer = setInterval(() => {
        this.Next();
      }, 5000);
      if (document.fullscreenEnabled) {
        document.documentElement.requestFullscreen();
      }
    }
  },
  beforeRouteLeave(to, from, next){
    clearInterval(this.timer);
    if(document.fullscreenElement && to.query.slideshow === undefined){
      document.exitFullscreen();
    }
    next();
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter {
  opacity: 0;
  transform: scale3d(2, 0.5, 1) translate3d(400px, 0, 0);
}

.slide-enter-to { transform: scale3d(1, 1, 1); }
.slide-enter-active,
.slide-leave-active { transition: 0.75s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
.slide-leave { transform: scale3d(1, 1, 1); }

.slide-leave-to {
  opacity: 0;
  transform: scale3d(2, 0.5, 1) translate3d(-400px, 0, 0);
}

.rotate-enter { transform: perspective(500px) rotate3d(0, 1, 0, 90deg); }
.rotate-enter-active,
.rotate-leave-active { transition: 0.75s; }
.rotate-leave-to { transform: perspective(500px) rotate3d(0, 1, 0, -90deg); }

</style>
