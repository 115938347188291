<template>
  <v-row>
    <v-col>
      <h1 style="font-size: 4vw">Share {{ event.name }} Photos</h1>
      <p></p>
      <h4 style="font-size: 3vw" v-if="event.twitter_tag">Use Tag <a target="_blank" :href="`https://twitter.com/intent/tweet?hashtags=${event.twitter_tag}`">#{{ event.twitter_tag }}</a> on Twitter</h4>
      <p></p>
      <h4 style="font-size: 3vw">Text {{ event.short_id }} to:<br>
        <a :href="`sms:${phone}`">{{ phone }}</a></h4>
      <p></p>
      <h4 style="font-size: 3vw">email photos to:<br>
        <a :mailto="`${event.short_id}@${email}`" @click.prevent="mail(event)">{{ event.short_id }}@{{ email }}</a></h4>
    </v-col>
    <v-col style="flex-grow: unset">
      <qrcode-vue :value="url" :size="size" class="d-print-none"></qrcode-vue>
      <qrcode-vue :value="url" :size="300" class="d-none d-print-block"></qrcode-vue>
    </v-col>
  </v-row>
</template>

<script>
//https://github.com/scopewu/qrcode.vue
import QrcodeVue from 'qrcode.vue'

export default {
  components: {QrcodeVue},
  props: ['event_id'],
  name: "EventInfo",
  computed: {
    url() {
      return window.location.href
    },
    size() {
      if(window.innerWidth < window.innerHeight) {
        return window.innerWidth - 50;
      } else {
        return window.innerHeight - 150;
      }
    },
    event(){
      return this.$store.state.event;
    },
    phone(){
      return process.env.VUE_APP_PHONE;
    },
    email(){
      return process.env.VUE_APP_EMAIL;
    }

  },
  methods:{
    mail(event){
      window.open(`mailto:${this.event.short_id}@${this.email}`);
    }
  },
  mounted() {
    if(this.$route.query.slideshow !== undefined) {
      if (!this.$store.state.images.length) {
        this.$store.dispatch('get_photos', {person_id: null, event_id: this.event_id});
      }
      this.timer = setInterval(() => {
        this.$router.replace({path: `/event/${this.event_id}/photo/${this.$store.state.images[0].id}?slideshow&index=0`});
      }, 15000);

    }
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    if (document.fullscreenElement && to.query.slideshow === undefined) {
      document.exitFullscreen();
    }
    next();
  }
}
</script>

<style scoped>

</style>
